$(document).ready(function() {
	if(!document.querySelector('.GPC0058')) return false;

	var $obj = $('.GPC0058');
	
	/* LGEITF-604 Start */
	var vipCheck;
	var observer = new MutationObserver(function(mutations) {
		mutations.forEach(function(mutation) {
			if (mutation.attributeName === 'data-is-vip') {
				vipCheck = mutation.target.getAttribute('data-is-vip');
				observer.disconnect();
			}
		});
	});
	observer.observe(document.querySelector('.GPC0058'), {
		attributes: true, childList: false, characterData: false
	});
	
	if($obj.attr('data-is-vip') != 'NULL'){
		vipCheck = $obj.attr('data-is-vip');
		observer.disconnect();
	}
	/* //LGEITF-604 End */
	
	var $slickInit = function(e){
		$obj.find('.item-group').each(function(){
			var slickMulti = $(this);
			slickMulti.slick({
				appendDots:slickMulti.siblings().find('.slick-dot-wrap'),
				infinite: false,
				listStyle: true,
				slidesToShow: 3,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 3000,
				arrows : true,
				dots: true,
				responsive: [
					{
						breakpoint: 769,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 640,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					},
				]
			});
			
			var sliderCounterText = slickMulti.siblings().find('.slick-dot-wrap .slick-dots .slick-active button').text(),
				sliderCounter = slickMulti.siblings().find('.slick-count .count-text'),
				sliderPrev = slickMulti.siblings().find('.slick-count .count-prev'),
				sliderNext = slickMulti.siblings().find('.slick-count .count-next');
			$(sliderCounter).text(sliderCounterText.replace('of', '/'));

			var updateSliderCounter = function(slick) {
				currentSlide = slickMulti.siblings().find('.slick-dot-wrap .slick-dots .slick-active button').text();
				$(sliderCounter).text(currentSlide.replace('of', '/'));
				var chkPrevDisable = slickMulti.find('.slick-prev.slick-arrow').is(':disabled'), 
					chkNextDisable = slickMulti.find('.slick-next.slick-arrow').is(':disabled');
				if(chkPrevDisable) {
					sliderPrev.attr('disabled', true);
				} else {
					sliderPrev.attr('disabled', false);
				}
				if(chkNextDisable) {
					sliderNext.attr('disabled', true);
				} else {
					sliderNext.attr('disabled', false);
				}
			};

			slickMulti.on('init', function(event, slick) {
				updateSliderCounter(slick);
			});

			slickMulti.on('afterChange', function(event, slick, currentSlide) {
				updateSliderCounter(slick, currentSlide);
			});

			sliderPrev.on('click', function(e) {
				slickMulti.slick('slickPrev');
			});

			sliderNext.on('click', function(e) {
				slickMulti.slick('slickNext');
			});

		});		
	};
	var resizeSlick = function(e){
		$obj.find('.item-group').each(function(){
			var slickMulti = $(this);
			/* LGCOMSPEED-6(6th) Start */
			if(document.readyState == 'complete') loadResizeScript();
			$(window).on('load resize orientationchange', loadResizeScript);
			function loadResizeScript() {
				var delay = 500;
				var timer = null;
				setTimeout(function() {
					clearTimeout(timer);
					var $dot_target = slickMulti.next('.slick-indicator');
					var $dot_ea = $dot_target.find('.slick-dots li').length;
					if ($dot_ea == 1) {
						$dot_target.hide()
					} else {
						$dot_target.show()
					}
				}, delay);
			}
			/* LGCOMSPEED-6(6th) End */
		});
	};
	var $unSlick = function(){
		if ($obj.find('.item-group').hasClass('slick-initialized')){
			var $slick = $obj.find('.item-group').slick('unslick');
		} else{
			return false
		}
	};
	var screenSlick = function(e) {
		var delay = 500;
		var timer = null;
		/* LGCOMSPEED-6(6th) Start */
		if(document.readyState == 'complete') loadResizeScript();
		$(window).on('load resize', loadResizeScript);
		function loadResizeScript(){
			$obj.find('.item-group').removeClass('load');
			clearTimeout(timer);
			timer = setTimeout(function(){
				$unSlick();
				if (e.matches) {								
					$slickInit();
				} else{
					$unSlick();
				}
				$obj.find('.item-group').addClass('load');
			}, delay);
		}
		/* LGCOMSPEED-6(6th) End */
	};
	var $slickSetInit = function(){			
		$obj.find('.item-group-set').each(function(){
			var slickMulti = $(this);
			slickMulti.slick({
				appendDots:slickMulti.siblings().find('.slick-dot-wrap'),
				infinite: false,
				listStyle: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				autoplay: true,
				autoplaySpeed: 3000,
				arrows : true,
				dots: true,
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3
						}
					},
					{
						breakpoint: 769,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						}
					},
					{
						breakpoint: 640,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						}
					},
				]
			});
			
			var sliderCounterText = slickMulti.siblings().find('.slick-dot-wrap .slick-dots .slick-active button').text(),
				sliderCounter = slickMulti.siblings().find('.slick-count .count-text'),
				sliderPrev = slickMulti.siblings().find('.slick-count .count-prev'),
				sliderNext = slickMulti.siblings().find('.slick-count .count-next');
			$(sliderCounter).text(sliderCounterText.replace('of', '/'));

			var updateSliderCounter = function(slick) {
				currentSlide = slickMulti.siblings().find('.slick-dot-wrap .slick-dots .slick-active button').text();
				$(sliderCounter).text(currentSlide.replace('of', '/'));
				var chkPrevDisable = slickMulti.find('.slick-prev.slick-arrow').is(':disabled'), 
					chkNextDisable = slickMulti.find('.slick-next.slick-arrow').is(':disabled');
				if(chkPrevDisable) {
					sliderPrev.attr('disabled', true);
				} else {
					sliderPrev.attr('disabled', false);
				}
				if(chkNextDisable) {
					sliderNext.attr('disabled', true);
				} else {
					sliderNext.attr('disabled', false);
				}
			};

			slickMulti.on('init', function(event, slick) {
				updateSliderCounter(slick);
			});

			slickMulti.on('afterChange', function(event, slick, currentSlide) {
				updateSliderCounter(slick, currentSlide);
			});

			sliderPrev.on('click', function(e) {
				slickMulti.slick('slickPrev');
			});

			sliderNext.on('click', function(e) {
				slickMulti.slick('slickNext');
			});
		});
	};
	var resizeSlickset = function(e){		
		$obj.find('.item-group-set').each(function(){
			var slickMulti = $(this);
			/* LGCOMSPEED-6(6th) Start */
			if(document.readyState == 'complete') loadResizeScript();
			$(window).on('load resize orientationchange', loadResizeScript);
			function loadResizeScript() {
				var delay = 500;
				var timer = null;
				setTimeout(function() {
					clearTimeout(timer);
					var $dot_target = slickMulti.next('.slick-indicator');
					var $dot_ea = $dot_target.find('.slick-dots li').length;
					if ($dot_ea == 1) {
						$dot_target.hide()
					} else {
						$dot_target.show()
					}
				}, delay);
			}
			/* LGCOMSPEED-6(6th) End */
		});
	};

	var $unSlickset = function(){
		if ($obj.find('.item-group-set').hasClass('slick-initialized')){
			var $slick = $obj.find('.item-group-set').slick('unslick');
		} else{
			return false
		}
	};
	var screenSlickset = function(e) {
		var delay = 500;
		var timer = null;
		/* LGCOMSPEED-6(6th) Start */
		if(document.readyState == 'complete') loadResizeScript();
		$(window).on('load resize', loadResizeScript);	
		function loadResizeScript(){
			$obj.find('.item-group-set').removeClass('load');
			clearTimeout(timer);
			timer = setTimeout(function(){
				$unSlickset();
				if (e.matches) {
					$obj.find('.item-group-set').each(function(){
						var target_grp = $(this).children(".item");
						for (var i = 0; i < target_grp.length; i += 4) {
							target_grp.slice(i, i + 4).wrapAll("<div class='item-set'></div>");
						}
					});					
					$slickSetInit();
				} else{
					if ($obj.find('.item-group-set').find('.item-set')){
						$obj.find('.item').unwrap('.item-set');	
					} else{
						return false
					};
					$slickSetInit();
				}
				$obj.find('.item-group-set').addClass('load');
			}, delay);
		}
		/* LGCOMSPEED-6(6th) End */
	};
	mql.maxLg.addListener(screenSlick,resizeSlick);
	screenSlick(mql.maxLg);
	resizeSlick(mql.maxLg);
	mql.minXl.addListener(screenSlickset, resizeSlickset);
	screenSlickset(mql.minXl);
	resizeSlickset(mql.minXl);

	// for web accessibility
	$(document).on('keyup , keydown', '.GPC0058 .item', function(e){
		var $targetItem = $(this);
		var $lastSns = $targetItem.find('.item-inner.on .button a');
		$targetItem.find('.item-inner').addClass('on');
		if($targetItem.find('.item-inner').hasClass('on')){
			if(e.type == 'keydown'){
				if($lastSns.is(':focus') && e.shiftKey == false){
					if(e.keyCode == 9){
						$targetItem.find('.item-inner').removeClass('on')
					}
				}
				if(e.shiftKey && e.keyCode == 9 && $targetItem.is(':focus')){
					$targetItem.find('.item-inner').removeClass('on')
				}
			} 
		}
	});
	$(document).on('mouseenter', '.GPC0058 .item', function(e){
		var $targetItem = $(this);
		if($targetItem.find('.item-inner').hasClass('on')){
			$targetItem.find('.item-inner').removeClass('on')
		}
	});

	$obj.find('.slide-pause').on('click',function(e){
		e.preventDefault();
		$(this).toggleClass('play')
		if ($(this).hasClass('play')) {
			$(this).text($(this).attr('data-title-play'));
			$(this).parents('.list-area').find('.slick-initialized').slick('slickPause');
		} else {
			$(this).text($(this).attr('data-title-stop'));
			$(this).parents('.list-area').find('.slick-initialized').slick('slickPlay');
		}
	});
	// for web accessibility
	// promotion area
	var GPC0058_WANumber = 0;
	$obj.find('.img-area .text-block').each(function() {
		var $target;
		if($(this).find('.sub-title') && !$(this).find('.sub-title').is(':empty')) {
			$target = $(this).find('.sub-title');
		} else if($(this).find('.body-copy') && !$(this).find('.body-copy').is(':empty')) {
			$target = $(this).find('.body-copy');
		}
		if($target) {
			$target.attr('id', 'waGPC0058_'+GPC0058_WANumber);
			$(this).find('.link a.link-text').attr('aria-describedby', 'waGPC0058_'+GPC0058_WANumber);
			GPC0058_WANumber++;
		}
	});
	// product area
	$obj.find('.list-area .item-group .item').each(function() {
		var $target;
		var obsBuynowFlag = $obj.find('input[name="obsBuynowFlag"]').val(); 
		if($(this).find('.model-name a') && !$(this).find('.model-name a').is(':empty')) {
			$target = $(this).find('.model-name a');
		}
		if($target) {
			$target.attr('id', 'waGPC0058_'+GPC0058_WANumber);
			//LGEVN-80
			if(obsBuynowFlag == 'Y'){
				$(this).find('a.btn').attr('aria-describedby', 'waGPC0058_'+GPC0058_WANumber)
			} else {
				$(this).find('a.btn').attr('aria-describedby', 'waGPC0058_'+GPC0058_WANumber).attr('role', 'button');
			}
			GPC0058_WANumber++;
		}
	});
	
	//LGEGMC-383
	//file download
	var fileDownload = function($link) {
		if($('form#formDown').length>0) $('form#formDown').remove();

		var fileBox = $link.closest('[data-file-download]');
		var flag = (fileBox.data('flag') || '').toUpperCase();
		var downtime = fileBox.data('downtime') || null;
		var opentime = fileBox.data('opentime') || null;

		if(flag=='Y') {
			// downtime error
			var $errorPop = $('#htmlOpenError');
			if($errorPop.length>0) {
				$errorPop.modal();
				$errorPop.find('.htmldowntime').text(downtime);
				$errorPop.find('.htmlopentime').text(opentime);
			} else {
				console.log('#htmlOpenError is required in HTML');
			}
		} else {

			var doc = $link.data('doc'),
				file = $link.data('file'),
				original = $link.data('original'),
				category = $link.data('category');

			var input = '<input type="hidden" id="DOC_ID" name="DOC_ID" value="' + doc +'" />';
			input += '<input type="hidden" id="ORIGINAL_NAME_b1_a1" name="ORIGINAL_NAME_b1_a1" value="' + original +'" />';
			input += '<input type="hidden" id="FILE_NAME" name="FILE_NAME" value="' + file +'" />';
			input += '<input type="hidden" id="TC" name="TC" value="DwnCmd" />';
			input += '<input type="hidden" id="GSRI_DOC" name="GSRI_DOC" value="GSRI" />'
			input += '<input type="hidden" id="SPEC_DOWNLOAD" name="SPEC_DOWNLOAD" value="N" />';

			/*LGEGMC-1035 start*/
            var openlocale="|AT|BE_FR|BG|CH_DE|CH_FR|CZ|DE|DK|EE|ES|FI|FR|GR|HR|HU|IT|LT|LV|NL|NO|PL|PT|RO|RS|SE|SK|UK|";
            var _target="_self";
            if((original.toLowerCase().indexOf('.pdf')>-1 || original.toLowerCase().indexOf('.bmp')>-1 || original.toLowerCase().indexOf('.jpg')>-1 || original.toLowerCase().indexOf('.jpeg')>-1||original.toLowerCase().indexOf('.png')>-1) && openlocale.indexOf("|"+COUNTRY_CODE.toUpperCase()+"|")>-1){
            	_target="_blank";
            }
            
			var form = $('<form />').attr({
				id: 'formDown',
				method: 'get',
				action: fileBox.data('action'),
				target: _target
			}).append(input);
			$('body').append(form);
			/*LGEGMC-1035 end*/

			$('#formDown').submit();
		}
	}
	
	
	$obj.on('click','.file-list a.link-text',function(e){
		e.preventDefault();
		fileDownload($(this));
	});

	// BV Static
	$obj.each(function() {
		var obj = $(this).find('.promo-area.active');
		runBVStaticPLP(obj);
	});
	// 탭 클릭시 실행되는 runBVStaticPLP 함수는 common.js에 존재함.

	// 20200406 START 이상현 - tab ui의 관계 정보 추가
	var addAriaRelationship = function(tg){
		if($(tg).find('.js-tab').length > 0){
			$(tg).find('.js-tab').find('[role="tab"]').each(function(idx) {
				$(this).attr('id', 'tabLabel_' + (idx+1));
				$(tg).find('[role="tabpanel"]').eq(idx).attr('aria-labelledby', 'tabLabel_' + (idx+1));
			});
		}
	}
	addAriaRelationship($obj);
	// 20200406 END
	
	/* LGEITF-604 Start */
	let priceSyncGPC0058 = {
		priceTemplateGPC0058: null,
		
		init: function(){
			const _this = this;
			
			_this.priceTemplateGPC0058 = $('#priceTemplateGPC0058').clone().html();
			$('#priceTemplateGPC0058').remove();
			
			_this.getPrice();
		},
		getPrice: function(){
			let arrModelId = new Array();
			let arrPromotionId = new Array();
			let tabs = $obj.find('.promo-area');
			let $target = tabs.find('.list-area .info-area');
			/*
			let modelCount = tabs.attr('data-model-count');
			let promotionId = tabs.attr('data-promo-id');
			*/
			
			$target.each(function(idx) {
				arrModelId[idx] = $(this).data('model-id');
			});
			tabs.each(function(idx) {
				arrPromotionId[idx] = $(this).data('promo-id');
			});
			
			const _this = this,
				url = $obj.attr('data-bp-price-sync-url'),
				param = {
					promotionId: arrPromotionId.length > 0 ? arrPromotionId.join() : '',
					modelIds: arrModelId.length > 0 ? arrModelId.join() : '',
					//modelCount: modelCount,
					bizType: $obj.data('biz-type')
				};
			
			if (!!param.promotionId) {
				ajax.call(url, param, 'json', function(data){
					if(data.status == 'success' && data.data){
						const $data = data.data[0];
						let commonData = {};
						commonData.obsMembershipLinkUrl = $data.obsMembershipLinkUrl;
						commonData.obsMembershipLinkTarget = $data.obsMembershipLinkTarget;
						commonData.obsMembershipLinkUseFlag = $data.obsMembershipLinkUseFlag;
						commonData.limitSaleTitle = $data.productMessages.limitSaleTitle;
						/* LGCOMPA-44 start */
						if(!!$data.guestPriceMessageUseFlag && $data.guestPriceMessageUseFlag == 'Y'){
							commonData.guestPriceMessageUseFlag = $data.guestPriceMessageUseFlag;
							commonData.guestPriceMessage = $data.guestPriceMessage;
						}
						/* LGCOMPA-44 end */
						
						for(var i=0; i<$data.modelList.length; i++){
							const p = $data.modelList[i],
								priceSyncHtml = priceSyncGPC0058.priceTemplateGPC0058;
							
							if (priceSyncHtml) {
								const $template = _this.makePriceSyncHtml(p, commonData, priceSyncHtml);
								const promotionArea = $obj.find('.promo-area[data-promo-id='+p.promotionId+']');
								const scop = promotionArea.find('.list-area .info-area .price-area.dnone[data-model-id='+p.modelId+']');
								scop.before($template);
								scop.remove();
							}
						}
					}
				});
			}
		},
		makePriceSyncHtml : function(p, commonData, template){
			if (template) {
				let obsMembershipLinkTarget = commonData.obsMembershipLinkTarget,
					obsMembershipLinkUrl = commonData.obsMembershipLinkUrl,
					rPrice = p.rPrice,
					rPriceCent = p.rPriceCent,
					rPromoPrice = p.rPromoPrice,
					rPromoPriceCent = p.rPromoPriceCent,
					discountMsg = p.discountMsg,
					bizType = p.bizType,
					obsLoginFlag = !!p.obsLoginFlag && p.obsLoginFlag ? 'Y' : 'N',
					limitSaleUseFlag= !!p.limitSaleUseFlag && p.limitSaleUseFlag == 'Y',
					obsLimitSale = !!p.obsLimitSale && p.obsLimitSale == 'Y',
					modelStatusCode = p.modelStatusCode,
					title = obsMembershipLinkTarget == '_blank' ? $(template).find('.member-text').data('target-message') : '',
					tempPrice = '',
					tempPromoPrice = '',
					tempLegal = '',
					obsMemberShipLinkStartHtml = '',
					obsMemberShipLinkEndHtml = '';
				
				if (commonData.obsMembershipLinkUseFlag == 'Y') {
					obsMemberShipLinkStartHtml = "<a href='"+ obsMembershipLinkUrl +"' target='"+ obsMembershipLinkTarget +"' title='" +title+ "'>";
					obsMemberShipLinkEndHtml = "</a>"
				}
				
				template = template.replace(/\*retailerPricingText\*/g, !!p.retailerPricingText ? p.retailerPricingText : '')
				.replace(/\*obsMemberShipLinkStart\*/g,obsMemberShipLinkStartHtml)
				.replace(/\*obsMemberShipLinkEnd\*/g,obsMemberShipLinkEndHtml)
				.replace(/\*obsMembershipPrice\*/g,!!p.rMembershipPriceCent ? changeFormatFullPriceSync(p.rMembershipPrice, p.rMembershipPriceCent) : (!!p.rMembershipPrice ? changeFormatPriceSync(p.rMembershipPrice) : ''))
				.replace(/\*limitSaleClass\*/g,(limitSaleUseFlag && obsLimitSale) ? 'limited-quantity-area' : '')
				.replace(/\*modelId\*/g,p.modelId);
				
				
				if (!!rPromoPriceCent) {
					tempPrice = changeFormatFullPriceSync(rPromoPrice, rPromoPriceCent);
					tempPromoPrice = changeFormatFullPriceSync(rPrice, rPriceCent);
					tempLegal = discountMsg;
				} else if (!!rPromoPrice) {
					tempPrice = changeFormatFullPriceSync(rPromoPrice);
					tempPromoPrice = changeFormatFullPriceSync(rPrice);
					tempLegal = discountMsg;
				} else if (!!rPriceCent) {
					tempPrice = changeFormatFullPriceSync(rPrice, rPriceCent);
				} else if (!!rPrice) {
					tempPrice = changeFormatFullPriceSync(rPrice);
				}
				
				let $html = $(template);
				
				$html.find('.purchase-price .number').text(tempPrice);
				$html.find('.product-price .number').text(tempPromoPrice);
				$html.find('.product-price .legal').text(tempLegal);
				
				if (bizType == 'B2B') {
					$html.find('.product-price').remove();
				} else {
					$html.find('div.msrp').remove();
				}
				
				if (p.retailerPricingFlag == 'N') {
					$html.find('.retailer').remove();
				} else {
					$html.find('.price-area > div').not('.retailer').remove();
					$html.find('.retailer').text(p.retailerPricingText);
				}
				
				if (limitSaleUseFlag && obsLimitSale) {
					//LGCOMIS-121
					if(COUNTRY_CODE.toLowerCase() == 'it'){
						$html.find('.price-vip-Installment').removeClass('d-none').find('.price-limited').text('');
					}else{
						$html.find('.price-vip-Installment').removeClass('d-none').find('.price-limited').text(commonData.limitSaleTitle); // LGCOMMON-54
					}
				} else {
					$html.find('.price-vip-Installment').addClass('d-none'); // LGCOMMON-54
				}
				
				if (!!p.membershipDisplayFlag && p.membershipDisplayFlag != 'Y') {
					//$html.find('.member-text').removeAttr('data-target-message');
					$html.find('.member-text').empty();
				}
				
				// LGCOMMONPL-218  start
				if ( p.obsLowestPriceFlag == 'Y' && !!p.rPromoPrice ) {
					if ( $html.find('.lowest-price-mark').length ) $html.find('.lowest-price-mark').removeClass('d-none');
					if ( $html.find('.lowest-price').length ) $html.find('.lowest-price').removeClass('d-none'); 
				} else {
					if ( $html.find('.lowest-price-mark').length ) $html.find('.lowest-price-mark').html('');
					if ( $html.find('.lowest-price').length ) $html.find('.lowest-price').html('');
				}
				// LGCOMMONPL-218  end
				
				if (tempPromoPrice == '') {
					$html.find('.product-price').remove();
				}
				
				if (tempPromoPrice == '' && tempPrice == '') {
					$html.find('.product-price').remove();
					$html.find('.purchase-price').remove();
				}
				
				/*
				if (obsCalculatorUseFlag && obsInstallmentPromotionUseFlag) {
					$html.find('.price-installment.none-calc').remove();
				} else {
					$html.find('.price-installment.calc').remove();
				}
				*/
				/* LGCOMPA-44 start */
				if(  SIGN_IN_STATUS == 'N'
				  && !!p.membershipDisplayFlag
				  &&   p.membershipDisplayFlag == 'Y'
				  && !!commonData.guestPriceMessageUseFlag
				  &&   commonData.guestPriceMessageUseFlag == 'Y'
				  && !!commonData.guestPriceMessage){
					$html.find('.member-text').html('').append(commonData.guestPriceMessage);
				}
				/* LGCOMPA-44 end */
				return $html.html();
			}
		}
	}
	
	if (!!!vipCheck || vipCheck == 'false' || $obj.attr('data-is-vip') == 'NULL') {
		priceSyncGPC0058.init();
	}
	/* LGEITF-604 End */
});
